import request from './request'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { encrypt_post } from '@/Http/api'
//工序工位
export function waittingList(data=[]) {
  let param = new URLSearchParams()
  param.append("page",data.page)
  data[data.key]=data.value
  delete data.key
  delete data.value
  data = encipherMent( JSON.stringify(data))
  param.append("value",data)
  return encrypt_post('/index.php/index/end_of_life_vehicles/awaitingDemolitionList',param)
}
export function toBeStarted(data) {
  return request({
    url: '/index.php/phone_pretreatment/toBeStarted',
    method: 'post',
    data
  })
}
//接单
export function takeOrders(data) {
  return request({
    url: '/index.php/phone_pretreatment/take_orders',
    method: 'post',
    data
  })
}
//开始任务
export function started(data) {
  return request({
    url: '/index.php/phone_pretreatment/started',
    method: 'post',
    data
  })
}
//待完成
export function toBeCompleted(data) {
  return request({
    url: '/index.php/phone_pretreatment/to_be_completed',
    method: 'post',
    data
  })
}
//工位
export function stationNopageList(data) {
  return request({
    url: '/index.php/working_procedure/station_nopage_list',
    method: 'post',
    data
  })
}
//员工
export function userJson(data) {
  return request({
    url: '/index.php/working_procedure_distribution/user_json',
    method: 'post',
    data
  })
}
export function userList(data) {
  return request({
    url: '/index.php/index/user/userList',
    method: 'post',
    data
  })
}
export function setTeammate(data) {
  return request({
    url: '/index.php/phone_pretreatment/set_teammate',
    method: 'post',
    data
  })
}
//完成任务
export function processRequirements(data) {
  return request({
    url: '/index.php/working_procedure_distribution/process_requirements',
    method: 'post',
    data
  })
}
export function destructPhotoTask(data) {
  return request({
    url: '/index.php/working_procedure_distribution/destruct_photo_task',
    method: 'post',
    data
  })
}
export function accordingToTask(data) {
  return request({
    url: '/index.php/working_procedure_distribution/according_to_task',
    method: 'post',
    data
  })
}
//
export function reusablePartsTask(data) {
  return request({
    url: '/index.php/working_procedure_distribution/reusable_parts_task',
    method: 'post',
    data
  })
}
//获取
export function recordsFind(data) {
  return request({
    url: '/index.php/working_procedure_distribution/recordsFind',
    method: 'post',
    data
  })
}
//大宗
export function bulkTasks(data) {
  return request({
    url: '/index.php/working_procedure_distribution/bulk_tasks',
    method: 'post',
    data
  })
}
//危废
export function wasteTask(data) {
  return request({
    url: '/index.php/working_procedure_distribution/waste_task',
    method: 'post',
    data
  })
}
//危废
export function productionRemarks(data) {
  return request({
    url: '/index.php/working_procedure_distribution/production_remarks',
    method: 'post',
    data
  })
}
//危废
export function submit(data) {
  return request({
    url: '/index.php/working_procedure_distribution/submit',
    method: 'post',
    data
  })
}
export function completed(data) {
  return request({
    url: '/index.php/phone_pretreatment/completed',
    method: 'post',
    data
  })
}
//调度
export function forkliftFindCar(data) {
  return request({
    url: '/index.php/working_procedure_distribution/forklift_find_car',
    method: 'post',
    data
  })
}
export function forkliftTask(data) {
  return request({
    url: '/index.php/working_procedure_distribution/forklift_task',
    method: 'post',
    data
  })
}
export function forkliftConfirm(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_confirm',
    method: 'post',
    data
  })
}

