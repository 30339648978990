<template>
<!-- 任务大厅 -->
    <div class="warper">
        <van-search
            v-model="value"
            show-action
           
            placeholder="请输入搜索关键词"
            @search="onSearch"
            >
            <select v-model="label" slot="label">
                <option value="carNumberPlate">车牌号：</option>
                <option value="defined_num">自定义编号：</option>
            </select>

            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
       
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                :offset="200"
            >
            <div class="content-infp" v-for="item in list" :key="item" :style="{padding:'0px 20px'}">
                <p>{{item.createTime}}</p>
                <div>
                    <!-- <p class="tit">{{item.working_procedure_name}}</p> -->
                    <p >自定义编号：{{item.defined_num}}</p>
                    <p>车牌号码：<span>{{item.carNumberPlate}}</span></p>
                    <p>车架号码：<span>{{item.vin}}</span></p>
                    <p>品牌型号：<span>{{item.vehicleBrand}} {{item.vehicleModel}}</span></p>
                    <p>拆解要求：<span>{{item.dismantle_allocation_remark?item.dismantle_allocation_remark:'拆解要求未定义'}}</span></p>
                </div>

                <van-image v-if="item.vehicleImages.split(',')[0]" radius="10" fit="fill" :src="item.vehicleImages.split(',')[0]" @click="imagePreview(item.vehicleImages.split(',')[0])"/>
                
                <p class="btn-p">
                    <van-button type="info" :style="{width:'80%'}" @click="allocation(item.id)">分配拆解任务</van-button>
                </p>
            </div>
            </van-list>
        </van-pull-refresh>
        <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :visible-item-count="4"
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
            @change="onChange"
        />
        </van-popup>
        <van-popup
            v-model="showAllocation"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <allotChai ref="ChaiRef" @closePopup="closePopup" v-if="showAllocation" @closeChai="closeChai" />
    </van-popup>
    </div>
</template>

<script>
import {Toast,Dialog,ImagePreview} from 'vant'
import { waittingList,takeOrders } from '@/api/dismantleWork'
import allotChai from './components/allotChai.vue'
export default {
    components: {
        allotChai,
    },
        data() {
            return {
                showAllocation:false,
                refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                finished: true,//下拉的状态 
                dismantle_allocation_remark:"",
                list:[],
                page:0,
                pageSize:10,
                imgs:require('../../assets/zw.jpg'),
                value:'',
                label:'carNumberPlate',
            }
        },
        methods: {
            closeChai(){
                this.showAllocation = false
                this.lodData()
            },
            allocation(id){
                this.showAllocation=true
                this.$nextTick(()=>{
                    this.$refs.ChaiRef.getUserList()
                    this.$refs.ChaiRef.listId = id
                })
            },
            closePopup(){
                this.showAllocation=false

            },
            imagePreview(url){
                let urls=[url]
                ImagePreview(urls)
            },
            onSearch(){
                this.list = [] 
                this.page = 0
               this.onLoad()
            },
            orderChange(id){
                Dialog.confirm({
                    title: '提示',
                    message: '是否接单？',
                    })
                    .then(async () => {
                        // on confirm
                        try{
                            const {data} = await takeOrders({id}).then(res=>res)
                            this.$toast.success(data.msg)
                            // this.page = 0
                            // this.lodData()
                            this.$emit('goNext',1)
                        }
                        catch{

                        }
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
             //加载 上拉
            onRefresh() {
                this.page = 0
                        // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                // this.onLoad();
            },
            lodData(){
                 this.list = [] 
                 this.page = 0
                 this.onLoad()
            },
            //下拉
            onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(async () => {
                    try{
                        let page = this_.page + 1
                        this_.page = page
                        let pageSize = this_.pageSize
                        let obj={
                            pageSize,
                            page,
                            key:this.label,
                            value:this.value,
                            defined_num_search_type:1
                        }
                        const {data} = await waittingList(obj).then(res=>res)
                        toast.clear();
                        console.log(data.data)
                        if(data.data.length==0){
                            //this_.list = [] 
                            this_.finished = true;
                            this_.refreshing = false
                        }else{
                            
                            let arr = data.data
                            arr.forEach(element => {
                                this_.list.push(element)
                            });
                            if(this_.list.length >= data.data.total){
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                this_.finished = false;
                                // this_.refreshing = false
                            }
                        }
                        this_.loading = false;
                    }
                    catch{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this_.$toast.fail('请求出错！')
                    }
                }, 1000);

            },
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    height: 70vh;
    padding: 10px;
    .content-infp{
        margin-top: 10px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #eee;
        .info{
            display: flex;
            .tit{
                font-size: 23px;
                font-weight: 700;
            }
             p{
                margin: 10px 0;
            }  
        }
        .btn-p{
            text-align: center;
        }
    }
}
</style>