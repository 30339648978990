<template>
        <!-- /**待开始任务 */ -->
    <div class="warper">
          <van-search
            v-model="value"
            show-action
           
            placeholder="请输入搜索关键词"
            @search="onSearch"
            >
            <select v-model="label" slot="label">
                <option value="carNumberPlate">车牌号码：</option>
                <option value="defined_num">自定义编号：</option>
            </select>

            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                :offset="200"
            >
            <div class="content-infp" v-for="item in list" :key="item">
                <p>{{item.createTime}}</p>
                <div class="info">
                    <div style="margin-left: 10px">
                        <p><span>自定义编号：</span>{{item.defined_num}}</p>
                        <p><span>车牌号码：</span>{{item.carNumberPlate}}</p>
                        <p><span>车架号码：</span>{{item.vin}}</p>
                        <p><span>品牌：</span>{{item.vehicleBrand}}</p>
                        <p><span>型号：</span>{{item.vehicleModel}}</p>
                    </div>
                </div>
               
                <p class="btn-p">
                    <van-button type="info" :style="{width:'80%'}" @click="openRw(item)">预处理任务分配</van-button>
                </p>
            </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import {Toast,Dialog} from 'vant'
import { noList } from '@/api/publics'
    export default {
        data() {
            return {
                 refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                finished: true,//下拉的状态 
                list:[],
                page:0,
                pageSize:10,
                 imgs:require('../../assets/zw.jpg'),
                 value:'',
                label:'carNumberPlate',
            }
        },
        methods: {
             onSearch(){
                this.list = [] 
                this.page = 0
               this.onLoad()
            },
            //调度
            dispatchHan(val){
                this.$router.push({path:'/DispatchPage',query:{id:val.id}})
            },
            //开始任务
            openRw(i){
                console.log(i)
                this.$router.push({path:'/AllocationPage',query:{id:i.id,is_sale_of:i.is_sale_of}})

            },
           //加载 上拉
            onRefresh() {
                this.page = 0
                        // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                // this.onLoad();
            },
             lodData(){
         
                 this.list = [] 
                 this.page = 0
                 this.onLoad()
            },
            //下拉
            onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(async () => {
                    try{
                        let page = this_.page + 1
                        this_.page = page
                        let pageSize = this_.pageSize
                        let obj={
                            pageSize,page,
                            [this.label]:this.value,
                            // value:this.value
                        }
                        const {data} = await noList(obj).then(res=>res)
                        toast.clear();
                        if(data.data.data.length==0){
                            //this_.list = [] 
                            this_.finished = true;
                            this_.refreshing = false
                        }else{
                            
                            let arr = data.data.data
                            arr.forEach(element => {
                                this_.list.push(element)
                            });
                            if(this_.list.length >= data.data.total){
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                this_.finished = false;
                                // this_.refreshing = false
                            }
                        }
                        this_.loading = false;
                    }
                    catch{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this_.$toast.fail('请求出错！')
                    }
                }, 1000);

            },
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    height: 70vh;
    padding: 10px;
    .content-infp{
        margin-top: 10px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #eee;
        .info{
           
            .tit{
                font-size: 23px;
                font-weight: 700;
            }   
             p{
                margin: 10px 0;
            }  
        }
        .btn-p{
            text-align: center;
            .van-button{
                margin: 0 5px;
            }
        }
    }
}
</style>