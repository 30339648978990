<template>
    <div>
        <Head :title="'拆解生产分配'" :out="'guide'" />
        <!-- @rendered="tabsChange" @click="tabsChange" -->
        <van-tabs class="vanTabs" v-model="active" @click="ChangeHan" >
            <van-tab title="预处理分配" v-if="defaults == '是'"><working ref="workingRef" /></van-tab>
            <van-tab title="待拆分配"> <waitting ref="waittingRef"  /> </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import Head from '@/components/Head'
import waitting from './waitting.vue'
import working from './working.vue'
import done from './done.vue'
import {Toast} from 'vant'
    export default {
        components: {
            Head,waitting,working,done
        },
        data() {
            return {
                active:0,
                defaults:''
              
            }
        },
        mounted () {
            if(this.$route.query.active == 0){
                this.active = 0
            }else if(this.$route.query.active == 1){
                this.active = 1
            }else  if(this.$route.query.active == 2){
                this.active = 2
            }else  if(this.$route.query.active == 3){
                this.active = 3

            }
            this.setUp()
            
        },
        methods: {
            setUp(){
                this.$http.post('/index.php//setUp/info',{id:34}).then(res=>{
                    if(res.data.code == 0){
                       
                        this.defaults = res.data.data.default
                        this.ChangeHan()
                    }else if(res.data.code == -1){
                          this.islogout()
                    }else{
                        this.$toast.fail(res.data.msg)
                    }
                 })
            },  
            goNext(e){
                this.active =e
                this.ChangeHan()
            },
            ChangeHan(){
                if(this.active == 0){
                    this.$nextTick(()=>{
                        
                        this.$refs.workingRef.lodData()
                    })
                }else if(this.active == 1){
                    this.$nextTick(()=>{
                        this.$refs.waittingRef.lodData()
                    })
                }else if(this.active == 2){
                    this.$nextTick(()=>{
                        this.$refs.doneRef.lodData()
                    })
                } else if(this.active == 3){
                    this.$nextTick(()=>{
                        this.$refs.lishRef.lodData()
                        // this.$refs.lishRef.onLoad()
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.vanTabs{
    margin-top: 55px;
}
.cont{
    margin-top: 55px;
}
.van-list{
    height: 50vh;
}
</style>